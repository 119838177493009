export const imageUrl =
  "https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/image/";

export const BASE_URL = "https://h5live.sishengsports.com/api";

export function SET_BASE_URL() {
  let baseUrl = "https://h5live.sishengsports.com/api";
  // 获取地址栏
  const url = window.location.href;
  //   判断是http还是https
  if (url.indexOf("https") > -1) {
    baseUrl = "https://h5live.sishengsports.com/api";
  } else {
    baseUrl = "http://h5live.sishengsports.com/api";
  }

  return baseUrl;
}
