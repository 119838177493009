/**
 * 此文件主要封装 axios 的get、post、delete等方法
 * （后续还可以添加文件的上传、下载、文件的导出等等）
 */

import { axiosInstance as axios } from "./axios.js";

//get
export function Get(url, parameter = {}) {
  return axios({
    url: url,
    method: "get",
    params: parameter,
  });
}

//post
export function Post(url, parameter = {}) {
  return axios({
    url: url,
    method: "post",
    data: parameter,
  });
}

//delete
export function Delete(url, parameter = {}) {
  return axios({
    url: url,
    method: "delete",
    params: parameter,
  });
}
