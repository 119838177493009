import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/game-list'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/game-list',
    name: 'GameList',
    component: () => import(/* webpackChunkName: "GameList" */ '../views/game-list.vue')
  },
  {
    path: '/live-list',
    name: 'LiveList',
    component: () => import(/* webpackChunkName: "LiveList" */ '../views/live-list.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "Live" */ '../views/live.vue')
  },
  {
    path: '/pull-video',
    name: 'PullVideo',
    component: () => import(/* webpackChunkName: "Live" */ '../views/pull-video.vue')
  },
  {
    path: '/video-list',
    name: 'VideoList',
    component: () => import(/* webpackChunkName: "VideoList" */ '../views/video-list.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import(/* webpackChunkName: "Videos" */ '../views/videos.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // 在每次路由导航触发前执行的逻辑  
  let token = localStorage.getItem('token')
  if (token) {
      next()
  } else {
    if (to.path == '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
});

// 防止连续点击多次路由报错
let originPush =  VueRouter.prototype.push;  //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject){
  // return routerPush.call(this, location).catch(err => err)
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    }else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, ()=>{}, ()=>{}); 
    }
}


export default router
